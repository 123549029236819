import React from "react"
// import { useReduceMotion } from "react-reduce-motion";
import { useScrollAnimation } from "../components/useScrollAnimation";
import { motion, useTransform } from "framer-motion";
import Layout from '../components/Layout'
import Seo from '../components/seo'
import { StaticImage } from "gatsby-plugin-image";
import BannerMp4 from '../videos/about.mp4'
import BannerWebm from '../videos/about.webm'
import BannerOgv from '../videos/about.ogv'
import BannerCover from '../videos/video_cover_what.jpg'
import TechLogos from '../components/TechLogos'
import YoutubeEmbed from "../components/YoutubeEmbed";

export default function Who() {

    // const prefersReducedMotion = useReduceMotion();
    const prefersReducedMotion = false; //react-reduce-motion fails at build time due to window

    const [bannerRef, bannerScroll] = useScrollAnimation();
    const bannerScale = useTransform(bannerScroll, [0, 0.5, 1], prefersReducedMotion ? [1, 1, 1] : [1, 1, 1.2]);

    const [section2Ref, section2Scroll] = useScrollAnimation();
    const section2Img1Scale = useTransform(section2Scroll, [0, 1], prefersReducedMotion ? [1, 1.2] : [1.4, 1]);

    return (
        <Layout>


            <Seo
                title='Hawke’s Bay software development | New Zealand | Meet Webfox'
                description='From our Hawke’s Bay development house, we build products for businesses right throughout New Zealand, and beyond.'
            />


            {/* section1 */}
            <div ref={bannerRef}>
                <section className="layer intro intro-default dark">
                    <motion.video animate={{ scale: bannerScale.get() }} id="background-video" autoPlay loop muted playsInline poster={BannerCover}>
                        <source src={BannerWebm} type='video/webm' />
                        <source src={BannerMp4} type='video/mp4' />
                        <source src={BannerOgv} type='video/ogg' />
                    </motion.video>

                    <div className="inner">
                        <div className="copy">
                            <h1>Who We Are</h1>
                        </div>
                    </div>
                </section>
            </div>

            {/* section 2 */}
            <section className="layer singlecol transparent">
                <div ref={section2Ref} className="inner">
                    <h2>We’re here to push boundaries and build what others can’t</h2>
                    <p>We’re problem solvers, we’re visionaries and we’re strategists. But we’re also geeks who love to stay hands-on with bringing big ideas to life with smart technology.</p>
                    <p>Our difference is in this accessibility - you deal directly with the team who are crunching code and ideating interfaces. While we can scale up and scale down to suit the size of your project, we prefer to leave inefficient layers out of it and continue to stay true to the benefits that come with small-agency service.</p>


                    {/* <div className="background-zoom-image">
                        <motion.div animate={{ scale: section2Img1Scale.get() }}>
                            <StaticImage
                                src="../images/team/devs-on-couch2.jpg"
                                alt="Webfox developers drinking coffee at work"
                                style={{ minWidth: "100%", minHeight: "100%", maxWidth: "100%" }}
                            />

                        </motion.div>
                    </div> */}

                    <YoutubeEmbed embedId="8T1tBU39n58" embedTitle="Meet Webfox" />

                    <p>We’ve been delivering innovative digital solutions for Kiwi businesses since 2009. We’ve got rock-solid and reliable processes in place and a highly experienced and long-standing team of seniors with the talent to deliver truly world-class software solutions.</p>
                    <h4>From our Hawke’s Bay development house, we build products for businesses right throughout New Zealand, and beyond.</h4>
                </div>
            </section>


            {/* section 3 */}
            <section className="layer black-bg dark">
                <div className="inner">
                    <div className="double-text">
                        <div className="grid col-2 align-top">
                            <div className="col">
                                <h2>Meet our lead team</h2>
                            </div>
                            <div className="col">
                                <p>We’re a tight-knit, multidisciplinary team blending high-level technical expertise with experienced, proactive project management.</p>
                            </div>
                        </div>
                    </div>
                    <div className="tiles">
                        <div className="grid col-3">
                            <div className="col">

                                <StaticImage
                                    src="../images/team/james-portrait.jpg"
                                    alt="James Simmmonds - Webfox Director"
                                    width={1000}
                                    height={1500}
                                />

                                <div className="copy">
                                    <h3>James Simmonds</h3>
                                    <p>Director &amp; Solutions Architect</p>
                                </div>
                            </div>
                            <div className="col">
                                <StaticImage
                                    src="../images/team/alexis-in-front-of-fox-2.jpg"
                                    alt="Alexis Simmonds - Webfox Studio Manager"
                                    width={1000}
                                    height={1500}
                                />

                                <div className="copy">
                                    <h3>Alexis Simmonds</h3>
                                    <p>Studio Manager &amp; <a href="https://www.pixisites.nz/" rel="noreferrer"
                                                               target="_blank">Pixi Website Director</a></p>
                                </div>
                            </div>
                            <div className="col">
                                <StaticImage
                                    src="../images/team/dan-portrait.jpg"
                                    alt="Daniel Lambert - Project Manager"
                                    width={1000}
                                    height={1500}
                                />

                                <div className="copy">
                                    <h3>Daniel Lambert</h3>
                                    <p>Project Manager</p>
                                </div>
                            </div>
                            <div className="col">
                                <StaticImage
                                    src="../images/team/louis-portrait.jpg"
                                    alt="Louis Coyle - Design Lead"
                                    width={1000}
                                    height={1500}
                                />

                                <div className="copy">
                                    <h3>Louis Coyle</h3>
                                    <p>Design Lead</p>
                                </div>
                            </div>
                            <div className="col">
                                <StaticImage
                                    src="../images/team/matt-portrait.jpg"
                                    alt="Matthew Hailwood - Lead Developer"
                                    width={1000}
                                    height={1500}
                                />
                                <div className="copy">
                                    <h3>Matthew Hailwood</h3>
                                    <p>Lead Developer</p>
                                </div>
                            </div>
                            <div className="col">
                                <StaticImage
                                    src="../images/team/surya-portrait.jpg"
                                    alt="Surya Van Lierde - Senior Developer"
                                    width={1000}
                                    height={1500}
                                />
                                <div className="copy">
                                    <h3>Surya Van Lierde</h3>
                                    <p>Senior Developer</p>
                                </div>
                            </div>
                            <div className="col">
                                <StaticImage
                                    src="../images/team/jim-portrait.jpg"
                                    alt="James Hulena - Web Developer"
                                    width={1000}
                                    height={1500}
                                />
                                <div className="copy">
                                    <h3>James (Jim) Hulena</h3>
                                    <p>Developer</p>
                                </div>

                            </div>
                            <div className="col">
                                <StaticImage
                                    src="../images/team/zeke-portrait-2.jpg"
                                    alt="Ezekiel Andersen"

                                />
                                <div className="copy">
                                    <h3>Ezekiel (Zeke) Andersen</h3>
                                    <p>Developer</p>
                                </div>
                            </div>
                            <div className="col">
                                <StaticImage
                                    src="../images/team/charlie-grass.jpg"
                                    alt="Charlie the office dog/fox"

                                />
                                <div className="copy">
                                    <h3>Charlie</h3>
                                    <p>Fun Patrol Officer (he takes the role seriously)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <TechLogos/>

        </Layout>
    )
}
