import React from 'react'

export default function YoutubeEmbed({ embedId, embedTitle }) {

    return (

        <div className="video-responsive">
            <iframe
                src={`https://www.youtube.com/embed/${embedId}`}
                title={`${embedTitle}`}
                width="560"
                height="315"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
            />
        </div>

    );
}