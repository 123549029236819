import React from 'react'

import AwsSVG from '../svgs/aws.svg'
import GoogleSVG from '../svgs/google.svg'
import PwaSVG from '../svgs/pwa.svg'
import LaravelSVG from '../svgs/laravel.svg'
import R3actSVG from '../svgs/r3act.svg'

export default function TechLogos() {

    return (

        <section className="layer tech-logos transparent border-top">
            <div className="inner">
                <div className="section-header center">
                    <h2>We use world-class technology to build reliable outcomes</h2>
                </div>
                <div className="logo-grid">

                <div className="tech-logo">
                        <AwsSVG />
                    </div>

                    <div className="tech-logo">
                        <LaravelSVG />
                    </div>

                    <div className="tech-logo">
                        <R3actSVG />
                    </div>

                    <div className="tech-logo">
                        <PwaSVG />
                    </div>

                    <div className="tech-logo">
                        <GoogleSVG />
                    </div>

                </div>
            </div>
        </section>

    );
}





